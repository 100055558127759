import React from "react"
import PropTypes from "prop-types"

// Utilities
import kebabCase from "lodash/kebabCase"

// Components
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { IndexHeader } from "../components/header"

const TagsPage = ({ data }) => {
  const {
    allMarkdownRemark: { group },
  } = data

  return (
    <Layout>
      <SEO
        title="Tags"
        description="All sorts of software engineering topics and ideas"
        path="/tags"
        keywords={group.map(tag => tag.fieldValue)}
      />
      <section className="max-w-3xl px-5 mx-auto">
        <IndexHeader>Tags</IndexHeader>
        <ul className="divide-gray-300 divide-y">
          {group.map(tag => (
            <li key={tag.fieldValue} className="pt-5">
              <Link
                to={`/tags/${kebabCase(tag.fieldValue)}/`}
                className="text-primary-400 hover:text-primary-500"
              >
                <div className="flex items-center space-x-4">
                  <h3 className="font-black text-3xl text-primary-500 capitalize">
                    {tag.fieldValue}
                  </h3>
                  <span className="bg-gray-200 bg-opacity-50 font-black px-3 py-1 rounded-full text-orange-400">
                    {tag.totalCount}
                  </span>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </section>
    </Layout>
  )
}

TagsPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
}

export default TagsPage

export const query = graphql`
  query {
    allMarkdownRemark(
      limit: 2000
      filter: { frontmatter: { draft: { ne: true } } }
    ) {
      group(field: frontmatter___tags___tagId) {
        fieldValue
        totalCount
      }
    }
  }
`
